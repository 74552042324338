@import url('https://fonts.googleapis.com/css2?family=Almarai:wght@700&family=Lato:wght@300;400&display=swap');

$color-primary: #b632a3;
$color-secondary: #5167d4;
$color-bg-dark: #282c34;

body {
    overflow: hidden;
    background: $color-bg-dark;
    font-family: 'Lato', sans-serif;
    color: rgba(255, 255, 255, 0.5);

    a {
        color: rgba(255, 255, 255, 0.5);
        transition: all 250ms cubic-bezier(.74, .06, .4, .92);
        position: relative;


        &:after {
            transition: height 250ms cubic-bezier(.74, .06, .4, .92);
            position: absolute;
            bottom: 0;
            left: 0;
            content: '';
            background: $color-primary;
            width: 100%;
            z-index: -1;
            height: 0;
            opacity: 0;
        }

        &:hover {
            color: rgba(255, 255, 255, 0.8);

            &:after {
                height: 55%;
                opacity: 1;
            }
        }

    }
}

.App {
    text-align: center;
}

.App-header {
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.links {
    opacity: 0;
    position: fixed;
    bottom: 0;
    right: 0;
    width: 100vh;
    text-align: right;
    transform: rotate(90deg);
    transform-origin: right;
    padding-top: 30px;
    -webkit-animation: mainFadeIn 2s forwards;
    -o-animation: mainFadeIn 2s forwards;
    animation: mainFadeIn 2s forwards;
    animation-delay: 1.6s;
    //text-align: left;
    //margin-top: 75px;
    a {
        text-transform: uppercase;
        text-decoration: none;
        font-size: 12px;

        + a {
            margin-left: 10px;
        }
    }

    //@media screen and (min-width: 600px) {

    //}
}


.box {
    width: 300px;
    height: 250px;
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;

    .title {
        width: 100%;
        position: relative;
        display: flex;
        align-items: center;
        height: 50px;

        .block {
            width: 0;
            height: inherit;
            background: $color-primary;
            position: absolute;
            animation: mainBlock 2s cubic-bezier(.74, .06, .4, .92) forwards;
            display: flex;
        }

        h1 {
            font-family: 'Almarai', sans-serif;
            color: #fff;
            font-size: 45px;
            -webkit-animation: mainFadeIn 2s forwards;
            -o-animation: mainFadeIn 2s forwards;
            animation: mainFadeIn 2s forwards;
            animation-delay: 1.6s;
            opacity: 0;
            display: flex;
            align-items: baseline;
            position: relative;
            left: -3px;

            span {
                width: 0;
                height: 0;
                -webkit-border-radius: 50%;
                -moz-border-radius: 50%;
                border-radius: 50%;

                background: $color-primary;
                -webkit-animation: load 0.6s cubic-bezier(.74, .06, .4, .92) forwards;
                animation: popIn 0.8s cubic-bezier(.74, .06, .4, .92) forwards;
                animation-delay: 2s;
                margin-left: 5px;
                margin-top: -10px;
                position: absolute;
                bottom: 13px;
                right: -12px;

            }
        }
    }

    .role {
        width: 100%;
        position: relative;
        display: flex;
        align-items: center;
        height: 30px;
        margin-top: -5px;
        text-align: left;

        .block {
            width: 0;
            height: inherit;
            background: $color-secondary;
            position: absolute;
            animation: secBlock 2s cubic-bezier(.74, .06, .4, .92) forwards;
            animation-delay: 2s;
            display: flex;
        }

        p {
            animation: secFadeIn 2s forwards;
            animation-delay: 3.2s;
            opacity: 0;
            font-weight: 400;
            font-family: 'Lato', sans-serif;
            color: #ffffff;
            font-size: 12px;
            text-transform: uppercase;
            letter-spacing: 5px;
        }
    }



    @media screen and (min-color-index:0) and(-webkit-min-device-pixel-ratio:0)
    {
        margin-top: -100px;
    }
}


@keyframes mainBlock {
    0% {
        width: 0%;
        left: 0;

    }
    50% {
        width: 100%;
        left: 0;

    }
    100% {
        width: 0;
        left: 100%;
    }
}

@keyframes secBlock {
    0% {
        width: 0%;
        left: 0;

    }
    50% {
        width: 100%;
        left: 0;

    }
    100% {
        width: 0;
        left: 100%;
    }
}

@keyframes mainFadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}


@keyframes popIn {
    0% {
        width: 0px;
        height: 0px;
        background: $color-primary;
        border: 0px solid #ddd;
        opacity: 0;
    }
    50% {
        width: 10px;
        height: 10px;
        background: $color-primary;
        opacity: 1;
        bottom: 45px;
    }
    65% {
        width: 7px;
        height: 7px;
        bottom: 0px;
        width: 15px
    }
    80% {
        width: 10px;
        height: 10px;
        bottom: 20px
    }
    100% {
        width: 7px;
        height: 7px;
        background: $color-primary;
        border: 0px solid #222;
        bottom: 8px;

    }
}

@keyframes secFadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 0.5;
    }
}


@keyframes top {
    0% {
        opacity: 0;
        bottom: -80px
    }
    100% {
        opacity: 1;
        bottom: 0px

    }
}

@keyframes icon {
    0% {
        opacity: 0;
        transform: scale(0.0);
    }
    50% {
        opacity: 1;
        transform: scale(1.3) rotate(-02deg);
    }
    100% {
        opacity: 1;
        bottom: 0px;
    }
}